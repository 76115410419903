import React, { useEffect } from "react";
import { FC, MouseEventHandler, useState } from "react";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as VerticalLine } from "../../assets/images/verticalLine.svg";
import { ReactComponent as ChevronDown } from "../../assets/images/chevronDown.svg";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import "./navBar.scss";
import { WhiteLogo } from "../../assets/icons";

const matchesUrl = (url: string) => {
  return window.location.pathname === url;
};

const NavBar: FC = () => {
  const [hamburger, setHamburger] = useState(false);

  useEffect(() => {
    if (hamburger) {
      // When hamburger is true, disable body overflow
      document.body.style.overflow = "hidden";
    } else {
      // When hamburger is false, enable body overflow
      document.body.style.overflow = "auto";
    }

    // Cleanup effect when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [hamburger]);

  return (
    <div className="navbar">
      <div className={`navbar__mb ${hamburger ? "active" : ""}`}>
        <Link to="/">
          {hamburger ? (
            <WhiteLogo className="navbar__logo" />
          ) : (
            <Logo className="navbar__logo" />
          )}
        </Link>
        <div
          onClick={() => setHamburger(!hamburger)}
          className={`navbar__mb-hamburger ${hamburger ? "active" : ""}`}
        >
          <section></section>
          <section></section>
          <section></section>
        </div>
      </div>
      {hamburger && (
        <div className="navbar__sidebar">
          <div className="navbar__sidebar-links">
            <Link className={matchesUrl("/") ? "active" : ""} to="/">
              Home
            </Link>
            <Link
              className={matchesUrl("/talent") ? "active" : ""}
              to="/talent"
            >
              Talent
            </Link>
            <Link
              className={matchesUrl("/employer") ? "active" : ""}
              to="/employer"
            >
              Employer
            </Link>
            <Link
              className={matchesUrl("/partner") ? "active" : ""}
              to="/partner"
            >
              Partner
            </Link>
          </div>
          <div className="navbar__sidebar-buttons">
            <button>
              <Link target="_blank" to={"https://tribaja.myflodesk.com/f04rnq9onr"}>
                Sign up for the waiting list
              </Link>
            </button>
          </div>
        </div>
      )}
      <div className="navbar__col-1">
        <Link to="/">
          <Logo className="navbar__logo" />
        </Link>
        <VerticalLine className="navbar__seperator" />
        <div className="navbar__links">
          <Link className={matchesUrl("/") ? "active" : ""} to="/">
            Home
          </Link>
          <Link className={matchesUrl("/talent") ? "active" : ""} to="/talent">
            Talent
          </Link>
          <Link
            className={matchesUrl("/employer") ? "active" : ""}
            to="/employer"
          >
            Employer
          </Link>
          <Link
            className={matchesUrl("/partner") ? "active" : ""}
            to="/partner"
          >
            Partner
          </Link>
        </div>
      </div>
      <div className="navbar__col-2">
        <Link
          target="_blank"
          className="link-button"
          to={"https://tribaja.myflodesk.com/f04rnq9onr"}
        >
          Sign up for the waiting list
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
