import React from "react";
import { Bookmark, Pin, Teams, Verified } from "../../assets/icons";
import { EmployerBackground, Job } from "../../assets/images";
import "./VerifiedEmployer.scss";

const VerifiedEmployer = ({
  employerData = {
    name: "Fluent LLC",
    verifiedStatus: "Tribaja Verified",
    tags: ["FINTECH", "MOBILE", "SOCIAL IMPACT", "SOFTWARE"],
    location: "San Francisco, CA",
    employeesRange: "1000-1500 Employees",
    image: EmployerBackground,
    logo: Job,
  },
}: {
  employerData: any;
}) => {
  return (
    <div className="verifiedEmployer">
      <div className="verifiedEmployer__bookmark">
        <Bookmark />
      </div>
      <img className="profile" src={employerData.logo} alt="employer profile" />
      <div
        className="image"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${employerData.image})`,
        }}
      ></div>
      <div className="verifiedEmployer__content">
        <div className="name">
          {employerData.name} <Verified />{" "}
          <span>{employerData.verifiedStatus}</span>
        </div>
        <div className="tags">{employerData.tags.join(" • ")}</div>
        <div className="details">
          <span>
            <Pin /> {employerData.location}
          </span>
          <span>
            <Teams /> {employerData.employeesRange}
          </span>
        </div>
        <div className="buttons">
          <button>Follow</button>
          <button className="invert">View Profile</button>
        </div>
      </div>
    </div>
  );
};

export default VerifiedEmployer;
