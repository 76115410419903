import React, { FC } from "react";
import {
  Bookmark,
  Clock,
  Global,
  Pin,
  Star,
  Verified,
} from "../../assets/icons";
import "./RecommendedJob.scss";

interface RecommendedJobProps {
  jobData: {
    company: string;
    matchPercentage: number;
    jobTitle: string;
    location: string;
    remoteType: string;
    postedTime: string;
    rating: number;
    image: any;
  };
}

const RecommendedJob: FC<RecommendedJobProps> = ({ jobData }) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <Star key={index} className={index < jobData.rating ? "filled" : ""} />
  ));

  return (
    <div className="recommendedJob">
      <div className="recommendedJob__match">
        <div className="progress">{jobData.matchPercentage}%</div>
        Job Match
      </div>
      <Bookmark className="recommendedJob__bookmark" />
      <img src={jobData.image} alt="job" />
      <div className="name">
        {jobData.company} <Verified />
      </div>
      <div className="rating">{stars}</div>
      <div className="title">{jobData.jobTitle}</div>
      <div className="line"></div>
      <div className="details">
        <div>
          <Pin />
          <span>{jobData.location}</span>
        </div>
        <div>
          <Global />
          <span>{jobData.remoteType}</span>
        </div>
        <div>
          <Clock />
          <span>{jobData.postedTime}</span>
        </div>
      </div>
      <div className="buttons">
        <button>Apply</button>
        <button className="invert">View Job</button>
      </div>
    </div>
  );
};

export default RecommendedJob;
