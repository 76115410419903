import React, { FC, useState } from "react";

import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import "./HeroCard.scss";
import { HeroArrow } from "../../assets/icons";

interface HeroCardProps {
  name: string;
  image: string;
  description: string;
}

const HeroCard: FC<HeroCardProps> = ({ name, image, description }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={() => setHover(true)}
      style={{
        backgroundImage: `var(--linear, linear-gradient(180deg, rgba(65, 65, 65, 0) 25%, #111 100%)), url(${image})`,
      }}
      className="heroCard"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AnimatePresence>
        {hover ? (
          <motion.div
            transition={{ duration: 0.3 }}
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: "100%",
            }}
            className="heroCard__container-hover"
          >
            <div>{name}</div>
            <p>{description}</p>
            <Link to="https://tribaja.myflodesk.com/f04rnq9onr">
              Join Now <HeroArrow className="arrow" />
            </Link>
          </motion.div>
        ) : (
          <motion.section
            transition={{ duration: 0.3 }}
            initial={{
              opacity: 0,
              y: "100%",
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: "100%",
            }}
            className="heroCard__container"
          >
            <div>{name}</div>
            <Link to="https://tribaja.myflodesk.com/f04rnq9onr">
              Join Now <HeroArrow className="arrow" />
            </Link>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeroCard;
