import React, { FC } from "react";
import './SignUpCard.scss';

interface SignupCardProps {
  paragraph: string;
  backgroundImage: string;
  dark: boolean;
  marTop: boolean;
  marBottom: boolean;

}

const SignUpCard: FC<SignupCardProps> = ({
  paragraph,
  backgroundImage,
  dark,
  marBottom,
  marTop
}) => {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="SignUpCard" style={style}>
      <p
        style={{
         color: dark ? "black" : "white",
        }}
      >{paragraph}
      </p>
      <button 
      style={{
        marginBottom: marBottom ? '10px' : '0px',
        marginTop: marTop ? '15px' : '0px',

      }}>Signup</button>
    </div>
  );
};
export default SignUpCard;
