import React, { FC, ReactNode, useEffect, useState } from "react";
import Slider from "react-slick";

import ReviewCard from "../../components/ReviewCard/ReviewCard";
import AdamSmithReview from "../../assets/images/AdamSmithReview.png";
import LynelleChainReview from "../../assets/images/LynelleChanReview.png";
import TaniaAparicioReview from "../../assets/images/TaniaAparicioReview.png";

import "./ReviewCards.scss";
import {
  GoogleReview,
  MicrosoftReview,
  SpotifyReview,
} from "../../assets/icons";

export interface Review {
  companyLogo: ReactNode;
  review: string;
  authorImage: string;
  authorName: string;
  position: string;
}
const reviewData: Array<Review> = [
  {
    companyLogo: <SpotifyReview />,
    review:
      '"Thank you Tribaja for connecting me with The Cru — I was hired as a Senior Designer and so far it’s been an amazing experience!"',
    authorImage: AdamSmithReview,
    authorName: "Nailey Nevarez",
    position: "Senior Product Designer at The Cru",
  },
  {
    companyLogo: <GoogleReview />,
    review:
      "Great News, I got hired at SOCi. I found the opportunity in Tribaja and then I reached out to Shannon and she shared a direct Tribaja link to the hiring manager. I really appreciate your support. Thank you!!.",
    authorImage: LynelleChainReview,
    authorName: "Tania Aparicio",
    position: "Product Associate at Soci",
  },
  {
    companyLogo: <MicrosoftReview />,
    review:
      '"Tribaja and Diversitech Summit has given me the opportunity to network with passionate individuals. I really liked being able to find a company that shares the same values as me."',
    authorImage: TaniaAparicioReview,
    authorName: "Lynelle Chan",
    position: "Data Migration Engineer at Greenhouse Software",
  },
  {
    companyLogo: <MicrosoftReview />,
    review:
      '"Truly thankful for this community in helping me with my job search and connecting me to my first opportunity in tech."',
    authorImage: LynelleChainReview,
    authorName: "Shenika Glover",
    position: "Business Development Rep at Intellum",
  },
  {
    companyLogo: <MicrosoftReview />,
    review:
      '"I found myself needing to connect with n expert during the interview process for my first choice company. Tribaja coached me all the way through to getting an offer letter."',
    authorImage: TaniaAparicioReview,
    authorName: "Shurouq Osman",
    position: "Sales Rep at Indeed",
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const ReviewCards: FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let slidesToShow;
  if (windowWidth < 800) {
    slidesToShow = 1; // Display 1 slide if width is less than 800px
  } else if (windowWidth < 1150) {
    slidesToShow = 2; // Display 2 slides if width is between 800px and 1150px
  } else {
    slidesToShow = 3; // Display 3 slides otherwise
  }

  return (
    <div className="review-cards">
      <Slider
        afterChange={(e) => setActiveIndex(e)}
        dots={false}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        infinite={true}
        autoplay={true}
        autoplaySpeed={3000}
      >
        {reviewData.map((review, index) => (
          <ReviewCard key={index.toString()} review={review} />
        ))}
      </Slider>
      <div className="carousel-dots">
        {reviewData.map((data, index) => (
          <button
            key={index}
            className={`carousel-button ${
              activeIndex === index && "active-carousel-button"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ReviewCards;
