import React, { useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import {
  EmployerScreen,
  ForgotPasswordScreen,
  HomePage,
  LoginScreen,
  PartnerScreen,
  SignUpScreen1,
  SignUpScreen2,
  SignUpScreenOTP,
  TalentScreen,
  VerifyEmailScreen,
} from "./containers";
import { AnimatePresence } from "framer-motion";
import { scrollToTop } from "./utils/scrollToTop";
import PrivacyPolicyScreen from "./containers/PrivacyPolicyScreen/PrivacyPolicyScreen";

const GTMNoScript = () => (
  <noscript>
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-TRDFZDSJ"
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    ></iframe>
  </noscript>
);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <div className="app">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="/talent" element={<TalentScreen />} />
            <Route path="/employer" element={<EmployerScreen />} />
            <Route path="/partner" element={<PartnerScreen />} />
            <Route path="/signup" element={<SignUpScreen2 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          </Routes>
        </AnimatePresence>
      </div>
      <GTMNoScript />
    </>
  );
};

export default App;
