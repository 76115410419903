import React, { FC } from "react";
import {
  AdobeLogo,
  GoogleLogo,
  MicrosoftLogo,
  SplunkLogo,
  SpotifyLogo,
  YoutubeLogo,
} from "../../assets/icons";

import "./CompaniesLogos.scss"

const CompaniesLogos: FC = () => {
  return (
    <div
    className="companies__logo"
    >
      <SpotifyLogo />
      <GoogleLogo />
      <AdobeLogo />
      <YoutubeLogo />
      <MicrosoftLogo />
      <SplunkLogo />
    </div>
  );
};

export default CompaniesLogos;
