import React from "react";
import Button from "../Button/Button";
import lookingForNewRolesImage from "../../assets/images/lookingForNewRolesImage.png";
import "./LookingForNewRolesSection.scss";

function LookingForNewRolesSection() {
  return (
    <>
      <section className="new-roles-section">
        <div>
          <h2>Looking for new roles & events in tech?</h2>
          <p>
            Subscribe to our weekly newsletter of hot jobs, resources,
            trainings, and events for diverse talent in tech.
          </p>
          <a className="link-button" href={"https://tribaja.myflodesk.com/npt99m9t64"}>Sign up!</a>
        </div>
        <img src={lookingForNewRolesImage} alt="looking for new roles" />
      </section>
    </>
  );
}

export default LookingForNewRolesSection;
