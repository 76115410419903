import React, { FC, useState } from "react";
import { Link } from "react-router-dom";

import NavBar from "../../components/NavBar/NavBar";
import ReviewCards from "../../components/ReviewCards/ReviewCards";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import LookingForNewRolesSection from "../../components/LookingForNewRolesSection/LookingForNewRolesSection";
import circles from "../../assets/images/5circles.png";

import { ReactComponent as HrSeperator } from "../../assets/images/HrSeperator.svg";

import CompaniesLogos from "../../components/CompaniesLogos/CompaniesLogos";
import communityImpactImage from "../../assets/images/communityImpactImage.png";

import ceoMessage from "../../assets/images/ceoMessage.png";

import {
  ArrowRight,
  HowItWorks1,
  HowItWorks2,
  HowItWorks3,
  HowItWorksHome1,
  HowItWorksHome2,
  HowItWorksHome3,
  NextArrow,
} from "../../assets/icons";

import {
  EmployerJoinNow,
  PartnerJoinNow,
  SymbolAward,
  SymbolCommunity,
  SymbolWomen,
  SymbolHeartInHand,
  TalentJoinNow,
  FullCard1,
  FullCard3,
  FullCard2,
  TalentMB,
  PartnerMB,
  EmployerMB,
  SmallDownArrow,
} from "../../assets/images";

import FullCard4 from "../../assets/images/fullCard4.svg";

import HeroCard from "../../components/HeroCard/HeroCard";

import "./HomePage.scss";
import FullWidthCard from "../../components/FullWidthCard/FullWidthCard";
import transition from "../../utils/transition";
import HeroCardMB from "../../components/HeroCardMB/HeroCardMB";
import Slider from "react-slick";

const HomePage: FC = () => {
  return (
    <>
      <div
        className="homepage"
        style={{
          backgroundImage: `url(${circles})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          backgroundSize: "95%",
        }}
      >
        <NavBar />
        <HeroSection />
        <WhoWeAre />
        <HowItWorksSection />
        <CommunityImpactSection />
        <WhoShouldJoin />
        <ReviewsSection
          title={"Success Stories"}
          paragraph="Join 15,000+ members that are already on their journey to a career in tech."
        />
        <LookingForNewRolesSection />
      </div>
      <Footer />
    </>
  );
};

export default transition(HomePage);

const WhoWeAre: FC = () => {
  return (
    <div className="whoWeAre">
      <div>Who are we?</div>
      <p>
        Welcome to our career platform! We're thrilled to assist you in finding
        the perfect training, job, and mentorship opportunities based on your
        preferences and experiences. To get started, please complete our quick
        and easy onboarding process.
      </p>
      <Link className="buttonWithArrow" to={"https://tribaja.myflodesk.com/f04rnq9onr"}>
        Sign Up Now <ArrowRight />
      </Link>
    </div>
  );
};

const HeroSection: FC = () => {
  const linearGradient =
    "linear-gradient(180deg, rgba(65, 65, 65, 0) 58.33%, #111 100%)";

  const heroCardsData = [
    {
      name: "Talent",
      image: PartnerJoinNow,
      description:
        "Connect with top employers and access exclusive job listings tailored to your skills and aspirations. Let Tribaja be your guide as you navigate your path to success.",
    },
    {
      name: "Employer",
      image: EmployerJoinNow,
      description:
        "Tap into a pool of talented professionals actively seeking new career opportunities. Showcase your company's brand, culture, and job openings to attract the best candidates. Let Tribaja streamline your hiring process.",
    },
    {
      name: "Partner",
      image: TalentJoinNow,
      description:
        "Are you a training provider or educational institution looking to connect with motivated individuals seeking to upskill or reskill? Collaborate with Tribaja and empower talent with the knowledge and tools they need to excel.",
    },
  ];

  const heroCardsDataMB = [
    {
      name: "Talent",
      color: "#91C7B1",
      image: TalentMB,
      description:
        "Connect with top employers and access exclusive job listings tailored to your skills and aspirations. Let Tribaja be your guide as you navigate your path to success.",
    },
    {
      name: "Employers",
      image: EmployerMB,
      color: "#FDCEB5",
      description:
        "Tap into a pool of talented professionals actively seeking new career opportunities. Showcase your company's brand, culture, and job openings to attract the best candidates. Let Tribaja streamline your hiring process.",
    },
    {
      name: "Partner",
      image: PartnerMB,
      color: "#BDAFEB",
      description:
        "Are you a training provider or educational institution looking to connect with motivated individuals seeking to upskill or reskill? Collaborate with Tribaja and empower talent with the knowledge and tools they need to excel.",
    },
  ];

  return (
    <section className="homepage-hero-section">
      <div className="homepage-hero-container">
        <h1 className="homepage-hero-text">
          Your trusted career guide for life's career changes
        </h1>
        <p className="homepage-hero-subtext">
          A talent marketplace and community for under supported tech talent
        </p>
        <Link to={"https://tribaja.myflodesk.com/f04rnq9onr"}>
          Let's Start <ArrowRight />
        </Link>
      </div>
      <div className="homepage-hero-card">
        {heroCardsData.map(({ name, image, description }) => (
          <HeroCard
            key={name}
            name={name}
            image={image}
            description={description}
          />
        ))}
      </div>
      {/* For Mobile */}
      <div className="homepage-hero-card-mb">
        {heroCardsDataMB.map(({ name, image, description, color }) => (
          <HeroCardMB
            key={name}
            name={name}
            image={image}
            description={description}
            color={color}
          />
        ))}
      </div>
      <CompaniesLogos />
    </section>
  );
};

const CommunityImpactSection: FC = () => {
  return (
    <section className="community-impact-section">
      <h2>Our Community Impact</h2>
      <HrSeperator />
      <p className="top-para">
        We are so excited and proud of our product. It's really easy to create a
        landing page for your awesome product.
      </p>
      <div className="community-impact-section__details">
        <div className="community-impact-section__cards">
          <h3>Some Numbers about us</h3>
          <div>
            <div
              style={{
                background: "#FDCEB5",
              }}
              className="card"
            >
              <span>78%</span>
              <p>BiPOC Community</p>
              <img src={SymbolHeartInHand} alt="Symbol Heart in hand" />
            </div>
            <div
              style={{
                background: "#91C7B1",
              }}
              className="card"
            >
              <span>67%</span>
              <p>Identify as Women</p>
              <img src={SymbolWomen} alt="Symbol Women" />
            </div>
            <div
              style={{
                background: "#BDAFEB",
              }}
              className="card"
            >
              <span>25%</span>
              <p>Average Increase</p>
              <img src={SymbolCommunity} alt="Symbol Community" />
            </div>
            <div
              style={{
                background: "#FEEDCC",
              }}
              className="card"
            >
              <span>10+</span>
              <p>Awards Won</p>
              <img src={SymbolAward} alt="Symbol Award" />
            </div>
          </div>
        </div>
        <div className="community-impact-section__image">
          <img src={communityImpactImage} alt="community impact" />
        </div>
      </div>
    </section>
  );
};

const HowItWorksSection: FC = () => {
  return (
    <section className="homepage__howItWorks">
      <h2>How does it work?</h2>
      <HrSeperator />
      <div className="how-it-works-section">
        <div className="icons__container">
          <HowItWorksHome1 />
          <SmallDownArrow />
          <HowItWorksHome2 />
          <SmallDownArrow />
          <HowItWorksHome3 />
        </div>
        <div className="grid-container">
          <div className="grid-item tranparent"></div>
          <div className="grid-item">
            <div className="svg-wrapper">
              <HowItWorksHome1 />
            </div>
          </div>
          <div className="grid-item horizontal-line">
            <NextArrow />
          </div>
          <div className="grid-item">
            <div className="svg-wrapper">
              <HowItWorksHome2 />
            </div>
          </div>
          <div className="grid-item horizontal-line">
            <NextArrow />
          </div>
          <div className="grid-item">
            <div className="svg-wrapper">
              <HowItWorksHome3 />
            </div>
          </div>
          <div className="grid-item tranparent"></div>
          <div className="colums">
            <p>Find Your Career Path</p>
            <p>
              Discover your ideal career path in the tech industry. Our platform
              assists you in identifying the right direction for your
              aspirations.
            </p>
          </div>
          <div className="colums">
            <p>Access Your Customized Dashboard</p>
            <p>
              Gain entry to your personalized dashboard, tailored to your chosen
              career path. Access resources, courses, and updates that align
              with your goals.
            </p>
          </div>
          <div className="colums">
            <p>Connect with Industry Experts</p>
            <p>
              Forge connections with seasoned industry experts. Engage in
              mentorship, workshops, and discussions that propel your journey
              towards tech excellence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhoShouldJoin: FC = () => {
  const whoShouldJoinData = [
    {
      img: FullCard1,
      title: "Talent",
      description: `If you're an aspiring tech professional seeking growth, networking, and opportunities, Tribaja is your platform. Join us to connect with employers, access resources, and chart your career path.`,
      backgroundColor: "#C1DFD4",
      reverse: false,
    },
    {
      img: FullCard2,
      title: "Employer",
      description: `Are you looking for diverse and skilled tech talent? Tribaja offers a gateway to a vibrant pool of under-supported professionals, simplifying your recruitment process.`,
      backgroundColor: "#fdceb5",
      reverse: true,
    },
    {
      img: FullCard4,
      title: "Partner",
      description: `Training providers and organizations passionate about tech education find a home in Tribaja. Partner with us to empower the tech workforce of the future through resources and mentorship.`,
      backgroundColor: "#5937CC66",
      reverse: false,
    },
  ];

  const [whoShouldJoinIndex, setWhoShouldJoinIndex] = useState(0);

  return (
    <div className="whoShouldJoin">
      <h2>Who should join?</h2>
      <HrSeperator />
      <div className="whoShouldJoin__container">
        {whoShouldJoinData.map((data) => (
          <FullWidthCard
            key={data.title}
            img={data.img}
            title={data.title}
            description={data.description}
            backgroundColor={data.backgroundColor}
            reverse={data.reverse}
          />
        ))}
      </div>
      <Slider
        afterChange={(e) => setWhoShouldJoinIndex(e)}
        dots={false}
        slidesToShow={1}
        slidesToScroll={1}
        infinite={true}
        autoplay={true}
        autoplaySpeed={3000}
        className="whoShouldJoin__slider"
      >
        {whoShouldJoinData.map((data) => (
          <FullWidthCard
            key={data.title}
            img={data.img}
            title={data.title}
            description={data.description}
            backgroundColor={data.backgroundColor}
            reverse={data.reverse}
          />
        ))}
      </Slider>
      <div className="carousel-dots">
        {whoShouldJoinData.map((_, index) => (
          <button
            key={index}
            className={`carousel-button ${
              whoShouldJoinIndex === index && "active-carousel-button"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

const CEOMessageSection: FC = () => {
  return (
    <section className="ceo-message-section">
      <h2>CEO Message</h2>
      <HrSeperator />
      <div>
        <img src={ceoMessage} alt="ceo message" />

        <div className="ceo-message-right">
          <p className="ceo-message-text">
            ”As an Afro-Latina and single mother, I struggled to make ends meet.
            I had a obtained my bachelor's degree and worked a corporate job but
            it wasn’t enough. I faced barriers and bias trying to climb the
            ladder and so I set out to make a career change. Transitioning into
            the tech industry changed my life and now I help others to do the
            same.”
          </p>
          <div className="salary-comparison">
            <div>
              <p>$67k</p>
              <p>Non-tech salary</p>
            </div>
            <div>
              <p>$67k</p>
              <p>Non-tech salary</p>
            </div>
          </div>
          <Button>Get started for free</Button>
        </div>
      </div>
    </section>
  );
};

interface ReviewSectionProps {
  title: string;
  paragraph: string;
}

const ReviewsSection: FC<ReviewSectionProps> = ({ title, paragraph }) => {
  return (
    <section className="reviews-section">
      <h2>{title}</h2>
      <HrSeperator />
      <p className="top-para">{paragraph}</p>
      <ReviewCards />
    </section>
  );
};

export { ReviewsSection };
