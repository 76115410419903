import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../assets/icons";
import { animated } from "react-spring";
import { use3dEffect } from "use-3d-effect";

import "./FullWidthCard.scss";
interface FullWidthCardProps {
  title: string;
  img: string;
  description: string;
  backgroundColor: string;
  reverse: boolean;
}

const FullWidthCard: FC<FullWidthCardProps> = ({
  title,
  img,
  description,
  reverse,
  backgroundColor,
}) => {
  const ref = React.useRef(null);
  const { style, ...mouseHandlers } = use3dEffect(ref);

  return (
    <animated.div
      style={{
        backgroundColor,
        flexDirection: reverse ? "row-reverse" : "row",
      }}
      className="fullWidthCard"
      ref={ref}
      {...mouseHandlers}
    >
      <div className="fullWidthCard__content">
        <div className="title">{title}</div>
        <p>{description}</p>
        <Link className="buttonWithArrow" to={"#"}>
          Learn more
          <ArrowRight />
        </Link>
      </div>
      <div
        className="fullWidthCard__image"
        style={{
          backgroundImage: `url(${img})`,
          backgroundPosition: reverse ? "0%" : "100%",
        }}
      ></div>
    </animated.div>
  );
};

export default FullWidthCard;
