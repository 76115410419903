import React, { FC } from "react";
import { BestMatchBadge, Experience, OpenFor, Pin } from "../../assets/icons";
import "./OurTalent.scss";

interface TalentProps {
  talentData: {
    name: string;
    role: string;
    company: string;
    location: string;
    experience: string;
    availability: string;
    image: string;
  };
}

const OurTalent: FC<TalentProps> = ({ talentData }) => {
  return (
    <div className="ourTalent">
      <div className="ourTalent__profile">
        <img src={talentData.image} alt={`${talentData.name} tribaja talent`} />
        <div className="content">
          <div>{talentData.name}</div>
          <span>
            {talentData.role} at {talentData.company}
          </span>
          <BestMatchBadge />
        </div>
      </div>
      <div className="ourTalent__details">
        <div>
          <span>
            <Pin /> From
          </span>
          {talentData.location}
        </div>
        <div>
          <span>
            <Experience />
            Experience
          </span>
          {talentData.experience}
        </div>
        <div>
          <span>
            <OpenFor />
            Open for
          </span>
          {talentData.availability}
        </div>
      </div>
      <div className="buttons">
        <button>View Profile</button>
        <button className="invert">Contact</button>
      </div>
    </div>
  );
};

export default OurTalent;
