import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./HeroCardMB.scss";
import { HeroArrow } from "../../assets/icons";

interface HeroCardMBProps {
  name: string;
  image: string;
  description: string;
  color: string;
}

const HeroCardMB: FC<HeroCardMBProps> = ({
  name,
  image,
  description,
  color,
}) => {
  return (
    <div
      className="heroCardMB"
      style={{
        background: color,
      }}
    >
      <div className="heroCardMB__container">
        <div className="heroCardMB__container-name">{name}</div>
        <p className="heroCardMB__container-paragraph">{description}</p>
        <Link to={"#"}>Join now</Link>
      </div>
      <img src={image} alt={name} />
    </div>
  );
};

export default HeroCardMB;
