import React, { FC, useState } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { Link } from "react-router-dom";
import {
  AccessCompanies,
  ArrowRight,
  EmployerBenefits2,
  EmployerBenefits4,
  EmployerBenefits5,
  EmployerBenefits6,
  FindTribe,
  HowItWorksEmployer1,
  HowItWorksEmployer2,
  HowItWorksEmployer3,
  NextArrow,
  ThumbsUp,
  WhatYouWillGet1,
  WhatYouWillGet3,
} from "../../assets/icons";
import {
  EmployerHero,
  HowWeHelpEmployer,
  HrSeperator,
  JohnTalent,
  SmallDownArrow,
  ZaraTalent,
} from "../../assets/images";

import "./EmployerScreen.scss";
import CompaniesLogos from "../../components/CompaniesLogos/CompaniesLogos";
import OurTalent from "../../components/OurTalent/OurTalent";
import { ReviewsSection } from "../HomePage/HomePage";
import LookingForNewRolesSection from "../../components/LookingForNewRolesSection/LookingForNewRolesSection";
import transition from "../../utils/transition";
import SignUpCard from "../../components/SignUpCard/SignUpCard";
import { employerPage_talentBackground } from "../../assets/images";
import Slider from "react-slick";

const benefitsData = [
  {
    icon: <WhatYouWillGet1 />,
    title: "AI Chatbot Support: Your Virtual Assistant",
    description:
      "Our Friendly Virtual Assistant. Let us guide you with personalized recommendations to enhance your Tribaja journey.",
  },
  {
    icon: <EmployerBenefits2 />,
    title: "Search Feature: Get Discovered by Talent",
    description:
      "Shine Bright for Talent. Catch the eyes of potential candidates with our wide-reaching search tool and save searches for convenience.",
  },
  {
    icon: <WhatYouWillGet3 />,
    title: "Verified Badge: Your Trusted Credential",
    description:
      "Your Trusted Emblem. Earn credibility effortlessly by acing a simple quiz, showcasing your commitment to excellence.",
  },
  {
    icon: <EmployerBenefits4 />,
    title: "ATS Integration: Direct Applications to Your Roles",
    description:
      "Your Path to Seamless Applications. Connect directly to your role openings through popular ATS, making hiring a breeze.",
  },
  {
    icon: <EmployerBenefits5 />,
    title: "Dashboard Access: Insights at Your Fingertips",
    description:
      "Your Insights Hub. Dive into engagement data, user demographics, and more, all at your fingertips for informed decision-making.",
  },
  {
    icon: <EmployerBenefits6 />,
    title: "Profile Page: Customize Your Presence",
    description:
      "Your Gateway to Talent. Build your dedicated employer profile to attract top tech professionals, showcasing your company culture and job opportunities.",
  },
];

const talentData1 = {
  name: "Zara L.",
  role: "UI/UX Designer",
  company: "LinkedIn",
  location: "San Francisco, CA",
  experience: "10 Years",
  availability: "Full Time Roles",
  image: ZaraTalent,
};

const talentData2 = {
  name: "John D.",
  role: "Software Engineer",
  company: "Google",
  location: "New York, NY",
  experience: "5 Years",
  availability: "Contract Roles",
  image: JohnTalent, 
};

const EmployerScreen: FC = () => {
  const [benefitsIndex, setBenefitsIndex] = useState(0);

  return (
    <>
      <div className="employerPage">
        <NavBar />
        <section
          className="hero-section"
          style={{
            backgroundImage: `url(${EmployerHero})`,
          }}
        >
          <div className="col-1">
            <h1>Discover Top Tech Talent for Your Team and Get Discovered</h1>
            <p>
              Discover a Multitude of Tech Professionals and Foster Diversity in
              Your Organizations
            </p>
            <Link
              style={{ justifyContent: "center" }}
              className="buttonWithArrow"
              to="#"
            >
              Become Verified
            </Link>
          </div>
        </section>
        <section className="employerPage__partnerships">
          <span>Brands we love</span>
          <CompaniesLogos />
        </section>
        <div className="how-we-help-section">
          <h2>How do we help employers?</h2>
          <HrSeperator />
          <div className="help-section-flex">
            <img src={HowWeHelpEmployer} alt="how we help" />
            <div className="text">
              <p className="text-title">
                Tribaja is your partner in building exceptional teams. With a
                focus on diversity and expertise, we provide a streamlined
                platform for employers to connect with top-tier tech talent.
              </p>
              <div className="cards">
                <div className="card">
                  <div className="svg-wrapper">
                    <ThumbsUp />
                  </div>
                  <div>
                    <p>Access to Diverse Talent:</p>
                    <p>
                      Discover a pool of under-supported tech professionals,
                      ensuring a broad spectrum of skills and experiences for
                      your company's growth.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="svg-wrapper">
                    <AccessCompanies />
                  </div>
                  <div>
                    <p>Effortless Recruitment</p>
                    <p>
                      Simplify your hiring process through our user-friendly
                      platform. Post jobs, review applications, and engage with
                      candidates seamlessly.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="svg-wrapper">
                    <FindTribe />
                  </div>
                  <div>
                    <p>Inclusive Workforce</p>
                    <p>
                      We believe in the power of diversity. Through Tribaja,
                      you'll have the opportunity to build a team that reflects
                      various perspectives and experiences, driving innovation
                      and success within your organization.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="employerPage__howItWorks">
          <h2>How does it work?</h2>
          <HrSeperator />
          <div className="how-it-works-section">
            <div className="icons__container">
              <HowItWorksEmployer1 />
              <SmallDownArrow />
              <HowItWorksEmployer2 />
              <SmallDownArrow />
              <HowItWorksEmployer3 />
            </div>
            <div className="grid-container">
              <div className="grid-item tranparent"></div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksEmployer1 />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksEmployer2 />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksEmployer3 />
                </div>
              </div>
              <div className="grid-item tranparent"></div>
              <div className="colums">
                <p>Create profile and complete assessment</p>
                <p>
                  This is an opportunity to showcase who you are. Take your time
                  and make a great impression.
                </p>
              </div>
              <div className="colums">
                <p>Become Tribaja verified</p>
                <p>
                  Enhance your credibility and visibility among top-tier tech
                  talent.
                </p>
              </div>
              <div className="colums">
                <p>Post opportunities and access talent</p>
                <p>
                  Post opportunities and access talent on Tribaja to connect
                  with a diverse range of highly skilled professionals eager to
                  advance their careers.
                </p>
              </div>
            </div>
          </div>
          <button>Become Verified</button>
        </section>
        <section className="employerPage__benefits">
          <h2>Benefits of Becoming a Tribaja Verified Company:</h2>
          <HrSeperator />
          <div className="employerPage__benefits-container">
            {benefitsData.map((d) => (
              <div key={d.title} className="employerPage__benefits-box">
                {d.icon}
                <div>{d.title}</div>
                <p>{d.description}</p>
              </div>
            ))}
          </div>
          <Slider
            afterChange={(e) => setBenefitsIndex(e)}
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            autoplay={true}
            autoplaySpeed={3000}
            className="employerPage__benefits-slider"
          >
            {benefitsData.map((data) => (
              <div key={data.title} className="employerPage__benefits-box">
                {data.icon}
                <div>{data.title}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </Slider>
        </section>
        <section className="employerPage__talent">
          <h2>Our Talent</h2>
          <HrSeperator />
          <div className="employerPage__talent-container">
            <OurTalent talentData={talentData1} />
            <OurTalent talentData={talentData2} />
            <SignUpCard
              paragraph="Sign up and get access to our full range of Talent"
              backgroundImage={employerPage_talentBackground}
              dark={false}
              marBottom={true}
              marTop={true}
            />
          </div>
        </section>
        {/* <ReviewsSection
          title="What companies are saying"
          paragraph="Join happy customers worldwide who are already hiring from Tribaja..
          "
        />
        <LookingForNewRolesSection /> */}
      </div>
      <Footer />
    </>
  );
};

export default transition(EmployerScreen);
