import React, { FC } from "react";
import { Bookmark } from "../../assets/icons";
import "./Recourse.scss";

interface RecourseProps {
  data: {
    image: string;
    name: string;
    tags: string[];
    description: string;
  };
}

const Recourse: FC<RecourseProps> = ({ data }) => {
  return (
    <div className="resource">
      <Bookmark className="resource__bookmark" />
      <img src={data.image} alt="resource" />
      <div className="name">{data.name}</div>
      <div className="tags">
        {data.tags.map((tag, index) => (
          <span key={index}>{tag}</span>
        ))}
      </div>
      <div className="description">{data.description}</div>
      <button>Learn more</button>
    </div>
  );
};

export default Recourse;
